import React, { useState, forwardRef } from "react";
import { FormattedMessage, FormattedPlural } from "react-intl";
import _, { set } from "lodash";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DateTime } from "luxon";
import { useQuery } from "@apollo/client";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  Root,
  TableScrollContainer,
  Table,
  LoadingContainer,
  ActiveFilters,
} from "./Dashboard.style";

import { Typography } from "../../ui/Typography/Typography";
import { PageSubheader } from "../PageSubheader/PageSubheader";
import { ButtonDropdown } from "../../ui/ButtonDropdown/ButtonDropdown";
import { Pagination } from "../Pagination/Pagination";
import { Loading } from "../Loading/Loading";
import { NoResults } from "./NoResults";
import { GET_POINTS_HISTORY } from "./config";

const columnHelper = createColumnHelper();
const columns = [
  columnHelper.accessor("dc", {
    header: "Date Earned",
    cell: (info) => DateTime.fromMillis(info.getValue()).toFormat("LL/dd/yyyy"),
    enableSorting: true,
  }),
  columnHelper.accessor("configuration", {
    id: "id",
    header: "Reward",
    cell: (info) => {
      // console.info(info);
      const val = info.getValue();
      const row = info.row.original;
      const note = row?.note === "" ? null : row?.note;
      // console.info({ row, val });
      return (
        val?.scope ??
        note ??
        `BONUS POINTS (${DateTime.fromMillis(row?.runDate).toFormat(
          "LL/dd/yyyy"
        )})`
      );
    },
    footer: (info) => info.column.id,
    enableSorting: false,
  }),
  columnHelper.accessor("runDate", {
    id: "period",
    header: "Period",
    cell: (info) => {
      const val = info.getValue();
      const row = info.row.original;
      return row?.configuration?.recurrence === "monthly"
        ? `${DateTime.fromMillis(row.runDate)
            .minus({ months: 1 })
            .toFormat("LLLL yyyy")}`
        : `N/A`;
    },
    footer: (info) => info.column.id,
    enableSorting: true,
  }),
  columnHelper.accessor("qualifyingTier", {
    header: "Tier",
    cell: (info) => _.capitalize(info.getValue()),
    footer: (info) => info.column.id,
    enableSorting: true,
  }),
  columnHelper.accessor("metrics", {
    header: "Period Sales",
    cell: (info) => {
      const row = info.row.original;
      const metrics = row?.metrics || [];
      const sales = metrics.find((m) => m.metricName === "sales");
      return sales
        ? sales.metricValue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        : "N/A";
    },
    footer: (info) => info.column.id,
    enableSorting: false,
  }),
  columnHelper.accessor("points", {
    header: "Points",
    cell: (info) => {
      const row = info.row.original;
      const val = row?.points ?? 0;
      return `+ ${val.toLocaleString("en-CA")}`;
    },
    footer: (info) => info.column.id,
    enableSorting: true,
  }),
  columnHelper.accessor("redeemed", {
    header: "Redeemed",
    cell: (info) => {
      const val = info.getValue() ?? 0;
      return `- ${val.toLocaleString("en-CA")}`;
    },
    footer: (info) => info.column.id,
    enableSorting: true,
  }),
  columnHelper.accessor("expires", {
    header: "Expires",
    cell: (info) => {
      const val = info.getValue();
      return DateTime.fromMillis(val).toFormat("LL/dd/yyyy");
    },
    footer: (info) => info.column.id,
    enableSorting: true,
  }),
];

export const PointsEarned = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sorting, setSorting] = useState([]);
  const [page, setPage] = useState(1);

  const filters = [];
  if (startDate) {
    filters.push({
      key: "dc",
      operator: "dateGTE",
      value: DateTime.fromJSDate(startDate).startOf("day").toISO(),
    });

    if (!endDate) {
      filters.push({
        key: "dc",
        operator: "dateLTE",
        value: DateTime.fromJSDate(startDate).endOf("day").toISO(),
      });
    }
  }

  if (endDate) {
    filters.push({
      key: "dc",
      operator: "dateLTE",
      value: DateTime.fromJSDate(endDate).endOf("day").toISO(),
    });
  }

  const {
    loading: pointsLoading,
    error: pointsError,
    data: pointsData,
  } = useQuery(GET_POINTS_HISTORY, {
    variables: {
      page,
      sort: sorting.map((itm) => ({
        key: itm.id,
        direction: itm.desc ? "-1" : "1",
      })),
      filters,
    },
  });

  const points = pointsData?.loyaltyresult?.find || {};
  // console.info({ points });

  const table = useReactTable({
    data: points?.docs ?? [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: (sortingUpdater) => {
      const newSorting = sortingUpdater(sorting);
      setSorting(newSorting);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Root>
      <Typography type="h2">
        <FormattedMessage defaultMessage="Points Activity" />
      </Typography>
      <PageSubheader>
        <Typography type="text-small">
          <span>
            {points?.docs?.length ?? 0}{" "}
            <FormattedPlural
              value={points?.docs?.length ?? 0}
              one="result"
              other="results"
            />
          </span>

          <ActiveFilters>
            {startDate && (
              <>
                {" "}
                <b>Filtered:</b>{" "}
                {DateTime.fromJSDate(startDate).toFormat("yyyy LLL dd")}
              </>
            )}

            {endDate && (
              <>
                {" to "}
                {DateTime.fromJSDate(endDate).toFormat("yyyy LLL dd")}
              </>
            )}
          </ActiveFilters>
        </Typography>

        <DatePicker
          selected={startDate}
          onChange={(dates) => {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
          }}
          startDate={startDate}
          endDate={endDate}
          customInput={<CustomReactDateInput />}
          enableTabLoop={false}
          selectsRange
        />
      </PageSubheader>

      {pointsLoading && (
        <LoadingContainer>
          <Loading global={false} />
        </LoadingContainer>
      )}

      {!pointsLoading && points?.docs?.length === 0 && <NoResults />}

      {!pointsLoading && points?.docs?.length > 0 && (
        <>
          <TableScrollContainer>
            <Table width="100%">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder ? null : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 8,
                                cursor: header.column.getCanSort()
                                  ? "pointer"
                                  : "default",
                              }}
                              {...{
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              <Typography type="text-small" color="#8f95a5">
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </Typography>

                              {header.column.getCanSort() && (
                                <>
                                  {{
                                    asc: (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16"
                                        width={16}
                                        height={16}
                                      >
                                        <path
                                          fill="#8f95a5"
                                          d="M10.647 6.339 8.044 3.736h-.092L5.354 6.34l-.706-.705 2.895-2.899h.917l2.896 2.9-.709.703Z"
                                        />
                                      </svg>
                                    ),
                                    desc: (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16"
                                        width={16}
                                        height={16}
                                      >
                                        <path
                                          fill="#8f95a5"
                                          d="m5.356 9.737 2.603 2.603h.092l2.598-2.604.706.705L8.46 13.34h-.917l-2.896-2.9.709-.703Z"
                                        />
                                      </svg>
                                    ),
                                  }[header.column.getIsSorted()] ?? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 16 16"
                                      width={16}
                                      height={16}
                                    >
                                      <path
                                        fill="#8f95a5"
                                        d="m5.356 9.737 2.603 2.603h.092l2.598-2.604.706.705L8.46 13.34h-.917l-2.896-2.9Zm5.291-3.398L8.044 3.736h-.092L5.354 6.34l-.706-.705 2.895-2.899h.917l2.896 2.9Z"
                                      />
                                    </svg>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td key={cell.id}>
                            <Typography type="text-secondary">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Typography>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableScrollContainer>
          <Pagination
            append={
              <Typography type="text-small" color="#8f95a5">
                {points?.docs?.length ?? 0}{" "}
                <FormattedPlural
                  value={points?.docs?.length ?? 0}
                  one="result"
                  other="results"
                />
              </Typography>
            }
            totalCount={points?.totals ?? 0}
            currentPage={points?.page ?? 1}
            pageSize={points?.limit ?? 15}
            onPaginate={setPage}
            style={{ marginTop: 48 }}
          />
        </>
      )}
    </Root>
  );
};

const CustomReactDateInput = forwardRef(({ onClick }, ref) => (
  <ButtonDropdown type="button" onClick={onClick} ref={ref}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={16}
      height={16}
    >
      <path
        fill="currentColor"
        d="M3.5 7h1v1h-1Zm2 0h1v1h-1Zm2 0h1v1h-1Zm2 0h1v1h-1Zm2 0h1v1h-1Zm-8 2h1v1h-1Zm2 0h1v1h-1Zm2 0h1v1h-1Zm2 0h1v1h-1Zm2 0h1v1h-1Zm-8 2h1v1h-1Zm2 0h1v1h-1Zm2 0h1v1h-1Zm2 0h1v1h-1Zm2 0h1v1h-1Zm2.499-8v11h-12V3h2V2h1v1h6V2h1v1Zm-11 1v1.5h10V4h-1v.5h-1V4h-6v.5h-1V4Zm0 9h9.5l.5-.5v-6h-10Z"
      />
    </svg>
    <Typography type="text-small">
      <FormattedMessage defaultMessage="Select a date" />
    </Typography>
  </ButtonDropdown>
));
